











































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import CartItem from "@/components/checkout/CartItem.vue";
import {
  Business,
  CartMembership,
  CartProduct,
  CartService,
  CartVoucher,
  Client,
  Role,
  User,
} from "@/types";
import CheckoutStep from "@/components/checkout/CheckoutStepper.vue";
import {
  validateVoucherCode,
  cashPayment,
  mpesaPayment,
  getQrCode,
  cardPayment,
  validatePhone,
} from "@/util/payment";
import OrderStoreModule from "@/store/modules/order";
import PaymentStoreModule from "@/store/modules/payment";
import PaymentGatewayModule from "@/store/modules/paymentVendor";
import GatewayModule from "@/store/modules/paymentVendorGateway";
import employeeStoreModule from "@/store/modules/employee";
import MembershipModule from "@/store/modules/clientMembership";
import { createNamespacedHelpers } from "vuex";
// import { loadStripe, Stripe } from "@stripe/stripe-js";
import { validateShippingCart } from "@/util/shipping";
import ClientModule from "@/store/modules/client";

const stripePubKey = process.env.VUE_APP_STRIPE_PUB_KEY;

const { mapActions: orderActions, mapGetters: orderGetters } =
  createNamespacedHelpers("ORDER_CHECKOUT");
const { mapActions: paymentActions } =
  createNamespacedHelpers("ORDER_PAYMENTS");
const { mapActions: gatewayActions, mapGetters: gatewayGetters } =
  createNamespacedHelpers("PAYMENT_GATEWAY");
const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEES_CHECKOUT");
const { mapActions: VendorGatewaysAction, mapGetters: VendorGatewayGetters } =
  createNamespacedHelpers("GATEWAY");
const { mapActions: membershipActions } =
  createNamespacedHelpers("_Membership");
const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("_Client");

const payment_items = [
  { title: "Shipping Details", value: "shipping" },
  { title: "Apply Voucher", value: "voucher" },
  { title: "Apply Membership", value: "membership" },
  { title: "Pay By Cash", value: "cash" },
  { title: "Pay By Mpesa", value: "mpesa" },
];

export default Vue.extend<any, any, any, any>({
  name: "CheckoutPage",
  components: { CartItem, CheckoutStep },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    requests: ["123"],
    stripe: null,
    isLoading: false,
    loadingMessage: "Processing Payment...",
    discount: 0,
    addToCart: false,
    voucherDialog: false,
    membershipDialog: false,
    cashDialog: false,
    cardDialog: false,
    qrDialog: false,
    mpesaDialog: false,
    shippingDialog: false,
    addressDialog: false,
    gift_code: "",
    paymentItems: payment_items,

    qr: {
      step: 1,
      timer: 300,
      creatingOrder: false,
      fetchingOrder: false,
      interval: null,
    },
    cash: {
      cash_amount: 0,
      cash_balance: 0,
      total_amount: 0,
    },
    card: {
      total_amount: 0,
      card_number: undefined,
      exp_date: "",
      cvc: undefined,
      client_name: "",
      client_email: "",
      client_phone: "",
      subscription: false,
    },
    mpesa: {
      total_amount: 0,
      phone: "",
      transactionId: "",
    },
    shippingAddress: {
      country: "",
      city: "",
      phone: "",
      addressline1: "",
      addressline2: "",
      zip_code: "",
    },
    shipping: {
      country: "",
      city: "",
      address: "",
      phone: "",
      notes: "",
    },
    orderID: "",
    qrcode: "",
    clientSubscriptions: [],
    selectedSubscription: null,
    subscriptionDiscount: 0,
    shippingMethods: [],
    selectedShippingMethod: undefined,
    shippingCost: 0,
    selectedAddress: undefined,
    addressChoice: undefined,
    selectedQRPaymentMethod: undefined,
  }),
  computed: {
    ...orderGetters(["getOrder"]),
    ...gatewayGetters(["paymentVendors", "getMyStripeAcc"]),
    ...employeeGetters(["employeePage"]),
    ...VendorGatewayGetters(["gateways"]),
    ...clientGetters(["getClient"]),
    isFlutterwaveSetup(): boolean {
      return this.gateways.isFlutterwaveSetup || false;
    },
    isStripeSetup(): boolean {
      return this.gateways.isStripeSetup || false;
    },
    flutterGateway(): any {
      return this.gateways.gateways
        ? this.gateways.gateways.filter((g: any) => g.type == "Flutterwave")[0]
        : null;
    },
    employees: function (): any[] {
      return this.employeePage?.docs || [];
    },
    products: function (): CartProduct[] {
      return this.$store.getters["cart/products"];
    },
    services: function (): CartService[] {
      return this.$store.getters["cart/services"];
    },
    memberships: function (): CartMembership[] {
      return this.$store.getters["cart/memberships"];
    },
    vouchers: function (): CartVoucher[] {
      return this.$store.getters["cart/vouchers"];
    },
    client: function (): Client {
      return this.$store.getters["cart/client"];
    },
    addresses: function (): any[] {
      if (this.client) {
        const user = (this.client as any).user;
        if (user) {
          return user?.addresses;
        } else {
          return [];
        }
      }
      return [];
    },
    cartQty: function (): number {
      return this.$store.getters["cart/quantity"];
    },
    cartTotal: function (): number {
      return this.$store.getters["cart/total"];
    },
    grandTotal: function (): number {
      return (
        this.cartTotal -
        this.discount -
        this.subscriptionDiscount +
        this.shippingCost
      );
    },
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    employee(): User {
      return this.role.user as User;
    },
    stripy(): any {
      if (this.role) {
        //return this.getMyStripeAcc((this.role.user as any)._id);
        return null;
      }
      return null;
    },
    shippingCheck(): any {
      return [this.products, this.cartQty];
    },
    order(): any {
      return this.getOrder(this.orderId);
    },
  },
  watch: {
    cash: {
      handler() {
        const { cash_amount, cash_balance } = this.cash;
        const total = this.grandTotal > 0 ? this.grandTotal : this.cartTotal;
        this.cash.cash_balance = total > cash_amount ? 0 : cash_amount - total;
        this.cash.total_amount = cash_amount - cash_balance;
      },
      deep: true,
      immediate: true,
    },
    gift_code: {
      handler(val) {
        if (val != "") {
          console.log("GiftCode", val);
        } else {
          this.discount = 0;
        }
      },
    },
    addressChoice() {
      this.fetchShippingMethods();
    },
    shippingCheck() {
      this.fetchShippingMethods();
    },
    role() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.loadOrder(this.orderId);
        this.fetchEmployeeList(params);
        this.getVendorGateways();
      }
    },
    selectedShippingMethod(value: any) {
      if (value) {
        this.shippingCost = value.rate;
      } else {
        this.shippingCost = 0;
      }
    },
    selectedAddress(value: any) {
      if (value) {
        this.shippingAddress = { ...value };
      } else {
        this.shippingAddress = {
          country: "",
          city: "",
          addressline1: "",
          addressline2: "",
          zip_code: "",
          phone: "",
        };
      }
    },
    order() {
      if (this.order && this.order.client) {
        this.$store.dispatch("cart/addCartClient", this.order.client);
      }
    },
  },
  created() {
    if (this.role) {
      const params = `?businessId=${(this.role.business as Business)?._id}`;
      this.loadOrder(this.orderId);
      this.fetchEmployeeList(params);
      this.getVendorGateways();
    }
  },
  methods: {
    ...orderActions([
      "createOrder",
      "updateOrder",
      "fetchOrderList",
      "retryOrderPayment",
      "checkOrderPayment",
    ]),
    ...paymentActions(["createPayment"]),
    ...gatewayActions(["fetchPaymentVendorList"]),
    ...employeeActions(["fetchEmployeeList"]),
    ...VendorGatewaysAction(["createTransaction", "fetchPaymentGateways"]),
    ...membershipActions([
      "fetchClientSubscriptions",
      "createMembershipSession",
      "verifyMembershipApply",
    ]),
    ...clientActions(["updateUserAddress", "fetchClient"]),
    checkAvailability(serviceRequest) {
      // alert("Testing");
      console.log(serviceRequest);
      // Call your API to check availability with the service request object
      // serviceRequest = { serviceId, businessId, startDateIso, duration }
    },
    handleServiceRemoval(serviceId) {
      console.log(serviceId);
      // Handle the removed service
    },
    getVendorGateways() {
      if (this.role) {
        const vendor_id = (this.role.business as Business)._id;
        this.fetchPaymentGateways(vendor_id);
        console.log("Fetch");
      }
    },
    fetchShippingMethods() {
      if (this.role && this.products.length > 0 && this.addressChoice) {
        validateShippingCart({
          business: this.vendor._id,
          cart_total: this.grandTotal - this.shippingCost,
          products: this.products,
          address: this.addressChoice,
        }).then((result) => {
          console.log(result);
          this.shippingMethods = result.data.methods;
        });
      }
    },
    updateShippingAddress() {
      this.shippingDialog = false;
    },
    addUserShippingAddress() {
      //updating user-address
      if (this.client) {
        const userId = (this.client as any).user._id;
        this.updateUserAddress({
          addresses: [{ ...this.shippingAddress }],
          userId,
        }).then((response) => {
          this.fetchClient(`?clientId=${this.client._id}`).then(
            (updatedClient: any) => {
              this.$store.commit("cart/SET_CLIENT", updatedClient);
            }
          );
          this.addressDialog = false;
          this.selectedAddress = undefined;
        });
      }
    },
    makePaymentCallback(response: any) {
      if (this.flutterGateway) {
        this.createTransaction({
          amount: response.amount,
          vendor_id: this.role._id,
          gateway_id: this.flutterGateway._id,
          transaction_id: response.transaction_id,
          customer_id: this.client._id,
          type: "CREDIT",
          notes: "Order Payment - On Premise",
        });
      }
    },
    closedPaymentModal(response: any) {
      console.log("payment modal is closed");
      console.log(response);
    },
    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },
    applyMembership() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Membership not applied!",
          text: "Please provide client Details",
        });
        return;
      }

      this.verifyMembershipApply({
        clientId: this.client._id,
        membershipId: this.memberships[0].id,
        quantity: this.memberships[0].quantity,
      }).then((res) => {
        const { success, message } = res;
        console.log(success);
        if (
          this.selectedSubscription &&
          this.memberships.length > 0 &&
          success
        ) {
          this.subscriptionDiscount = this.memberships[0].sub_total || 0;
          const { membership, quantity } = this.memberships[0];
          this.saveMembershipSession({
            businessId: this.vendor._id,
            clientId: this.client._id,
            membershipId: membership._id,
            quantity,
          });
        } else {
          this.subscriptionDiscount = 0;
        }
        this.membershipDialog = false;
      });
    },
    openDialog(type: string) {
      if (type == "shipping") {
        if (this.client) {
          const { phone } = this.client;
          this.shipping.phone = phone;
        }
        this.shippingDialog = true;
      }

      if (type == "voucher") {
        this.voucherDialog = true;
      }

      if (type == "membership") {
        if (!this.client) {
          this.$swal.fire({
            icon: "error",
            title: "Process not initialized!",
            text: "Please provide client Details",
          });
          return;
        }
        this.fetchClientSubscriptions({
          email: this.client.email,
          vendor_id: this.vendor._id,
          membership_id: 1,
        }).then((r) => {
          console.log(r);
          this.clientSubscriptions = r;
          this.membershipDialog = true;
        });
      }

      if (type == "cash") {
        this.cashDialog = true;
        this.cash = {
          cash_amount: 0,
          cash_balance: 0,
          total_amount: 0,
        };
      }

      if (type == "card") {
        if (this.client) {
          this.card.client_name = this.client.fullName;
          this.card.client_email = this.client.email;
          this.card.client_phone = this.client.phone;
        }
        this.card.total_amount = this.grandTotal;
        this.cardDialog = true;
      }

      if (type == "mpesa") {
        if (!this.order) return;
        this.mpesa.total_amount = this.order.cost || 0;
        this.mpesaDialog = true;
      }
      if (type == "qr") {
        if (!this.client) {
          this.$swal.fire({
            icon: "error",
            title: "Payment Not Initialized",
            text: "Please provide client Details",
          });
          return;
        }

        if (this.services.length == 0 && this.products.length == 0) {
          this.$swal.fire({
            icon: "error",
            title: "Cart error",
            text: "Please add items to cart",
          });
          return;
        }
        this.qrDialog = true;
      }
    },
    formatPhone(phone: string) {
      const codedPlus =
        /^(?:\+254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const zero = /^(?:0)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const coded =
        /^(?:254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;

      if (phone.match(codedPlus)) {
        const newPhoneNumber = phone.substring(1);
        return newPhoneNumber;
      }

      if (phone.match(coded)) {
        return phone;
      }

      if (phone.match(zero)) {
        const newPhoneNumber = phone.substring(1);
        const newPhone = `254${newPhoneNumber}`;
        return newPhone;
      }
      return phone;
    },
    flutterPayment() {
      if (!this.flutterGateway) {
        return;
      }

      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }
      console.log(this.memberships);
      let membershipMeta: any = null;
      if (this.memberships.length > 0) {
        const {
          id,
          period,
          start_date,
          type,
          unitPrice,
          membership: { name },
        } = this.memberships[0];
        membershipMeta = {
          start_date,
          duration: period,
          interval: type,
          membership: true,
          amount: unitPrice,
          membership_title: name || "",
          membership_id: id,
        };
      }

      const payload = {
        tx_ref: this.generateReference(),
        amount: this.grandTotal,
        currency: "KES",
        payment_options: "card",
        redirect_url: "",
        meta: {
          order_id: 1234,
          client_id: this.role._id,
          vendor_id: this.vendor._id,
          ...membershipMeta,
        },
        customer: {
          name: this.client.fullName,
          email: this.client.email,
          phone: this.client.phone,
        },
        subaccounts: [
          {
            id: this.flutterGateway.accountID,
            transaction_charge_type: "flat",
            transaction_charge: 30,
          },
        ],
        customizations: {
          title: "Agizo",
          description: "Agizo Payments",
          logo: require("@/assets/logo.png"),
        },
        callback: this.makePaymentCallback,
        onclose: this.closedPaymentModal,
      };
      (this as any).payWithFlutterwave(payload);
    },
    async generateQRCode() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });

        this.resetQrPayment();
        return;
      }

      if (this.services.length == 0 && this.products.length == 0) {
        this.$swal.fire({
          icon: "error",
          title: "Cart error",
          text: "Please add items to cart",
        });
        this.resetQrPayment();
        return;
      }

      let shipping: any = null;
      this.qr.creatingOrder = true;

      if (this.products.length > 0) {
        if (!this.addressChoice) {
          this.$swal.fire({
            icon: "error",
            title: "Please select a shipping Address",
            text: "Please select a shipping Address",
          });
          this.resetQrPayment();
          return;
        }
        if (!this.selectedShippingMethod) {
          this.$swal.fire({
            icon: "error",
            title: "Please select a shipping Method",
            text: "Please select a shipping Method",
          });
          this.resetQrPayment();
          return;
        }

        const { country, city, addressline1, phone } = this
          .addressChoice as any;

        if (country == "" || city == "" || addressline1 == "" || phone == "") {
          this.$swal.fire({
            icon: "error",
            title: "Shipping Address",
            text: "Please provide your shipping Details",
          });
          this.resetQrPayment();
          return;
        }

        shipping = {
          contact: phone,
          delivery_note: "notes",
          shipping_method: (this.selectedShippingMethod as any).shipping_method
            ._id,
          country,
          city,
          address: addressline1,
          shipping_cost: this.shippingCost,
        };
      }

      if (this.orderID == "") {
        try {
          const order: any = await this.saveOrder({
            clientId: this.client._id,
            notes: "Order Notes",
            cost: this.grandTotal - this.shippingCost,
            shipping,
            paymentMethod: this.selectedQRPaymentMethod ?? "card",
            products: this.products.map((product) => {
              return {
                product: product.product._id,
                sku: product.sku,
                quantity: product.quantity,
                unitPrice: product.unitPrice,
                total: product.sub_total,
              };
            }),
            services: this.services.map((service) => {
              return {
                name: service.name,
                service: service.id,
                staff: service.staff,
                appointmentDate: service.appointmentDate,
                appointmentTime: service.appointmentTime,
                quantity: service.quantity,
                unitPrice: service.unitPrice,
                total: service.sub_total,
              };
            }),
          });

          if (!order) {
            this.$swal.fire({
              icon: "error",
              title: "Payment Not Initialized",
              text: "Please Create an Order",
            });
            this.resetQrPayment();
            return;
          } else {
            this.orderID = order._id;
          }
        } catch (error) {
          this.resetQrPayment();

          return;
        }
      }

      ///
      const fcm_token = localStorage.getItem("fcm-token");
      const payload = {
        currency: "KES",
        country: "kenya",
        vendor_id: this.$store.getters.user._id,
        client_id: this.client._id,
        order_id: this.orderID,
        fcm_token,
      };
      try {
        const { data } = await getQrCode(payload);
        this.qrcode = data.qrcode;

        this.qr.step = 2;
        this.qr.creatingOrder = false;

        this.qrTimer();
      } catch (error: any) {
        this.resetQrPayment();
        this.$store.dispatch(
          "setToast",
          {
            title: "Request failed!",
            type: "error",
            text: error.response?.data?.error?.message,
          },
          { root: true }
        );
      }

      try {
        this.qr.step = 3;
        this.qr.fetchingOrder = true;

        (this.qr.interval as any) = setInterval(async () => {
          const { order }: any = await this.fetchOrder(this.orderID);

          console.log(order);

          if (order.paymentStatus == "completed") {
            this.resetQrPayment();

            this.$router.push(`/order/${this.orderID}`);
          }
        }, 3000);
      } catch (error: any) {
        this.resetQrPayment();
        this.$store.dispatch(
          "setToast",
          {
            title: "Request failed!",
            type: "error",
            text: error.response?.data?.error?.message,
          },
          { root: true }
        );
      }
    },
    async doMpesaPayment() {
      const { phone, transactionId } = this.mpesa;

      if (!this.order) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide order Details",
        });
        return;
      }

      if (transactionId) {
        this.isLoading = true;
        this.loadingMessage = "Checking Mpesa Payment...";
        this.checkOrderPayment({
          id: this.order._id,
          data: { transactionId },
        })
          .then(() => {
            this.resetForms();
            this.$router.push(`/order/${this.order._id}`);
            this.mpesaDialog = false;
            this.$swal.fire(
              "Success",
              "Order payment Checking underway!",
              "success"
            );
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title: "Order Payment Failed!",
              text:
                (e as Error).message || "Error while processing Order Payment",
            });
          })
          .finally(() => (this.isLoading = false));
        return;
      }

      if (!validatePhone(phone)) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide a valid phone Number!",
        });
        return;
      }

      const { _id, cost } = this.order;
      this.isLoading = true;
      this.loadingMessage = "Processing payment...";
      this.retryOrderPayment({
        id: _id,
        payment: {
          method: "m-pesa",
          currency: "KES",
          amount: cost || 0,
          phone_number: phone,
        },
      })
        .then(() => {
          this.resetForms();
          this.$router.push(`/order/${this.order._id}`);
          this.mpesaDialog = false;
          this.$swal.fire("Success", "Order payment request sent!", "success");
        })
        .catch((e) => {
          this.$swal.fire({
            icon: "error",
            title: "Order Payment Failed!",
            text:
              (e as Error).message || "Error while processing Order Payment",
          });
        })
        .finally(() => (this.isLoading = false));
    },
    async doMpesaPayment2() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }

      let shipping: any = null;

      if (this.products.length > 0) {
        const { country, city, addressline1, phone } = this
          .addressChoice as any;
        if (!this.selectedShippingMethod) {
          this.$swal.fire({
            icon: "error",
            title: "Please select a shipping Method",
            text: "Please select a shipping Method",
          });
          return;
        }

        if (country == "" || city == "" || addressline1 == "" || phone == "") {
          this.$swal.fire({
            icon: "error",
            title: "Shipping Address",
            text: "Please provide your shipping Details",
          });
          return;
        }
        shipping = {
          contact: phone,
          delivery_note: "notes",
          shipping_method: (this.selectedShippingMethod as any).shipping_method
            ._id,
          country,
          city,
          address: addressline1,
          shipping_cost: this.shippingCost,
        };
      }

      if (this.orderID == "") {
        const order: any = await this.saveOrder({
          clientId: this.client._id,
          notes: "Order Notes",
          cost: this.grandTotal,
          paymentMethod: "m-pesa",
          shipping,
          products: this.products.map((product) => {
            return {
              product: product.product._id,
              sku: product.sku,
              quantity: product.quantity,
              unitPrice: product.unitPrice,
              total: product.sub_total,
            };
          }),
          services: this.services.map((service) => {
            return {
              name: service.name,
              service: service.id,
              staff: service.staff,
              appointmentDate: service.appointmentDate,
              appointmentTime: service.appointmentTime,
              quantity: service.quantity,
              unitPrice: service.unitPrice,
              total: service.sub_total,
            };
          }),
        });

        if (!order) {
          this.$swal.fire({
            icon: "error",
            title: "Payment Not Initialized",
            text: "Please provide client Details",
          });
          return;
        } else {
          this.orderID = order._id;
        }
      }

      try {
        this.isLoading = true;
        this.loadingMessage = "Processing Payment...";
        const { total_amount, phone } = this.mpesa;
        const _phone = this.formatPhone(phone);
        // let payload = {
        //   amount: total_amount,
        //   party_a: _phone,
        //   phone: _phone,
        //   gateway_id: "string",
        //   currency: "KES",
        //   country: "Kenya",
        //   commission: 0,
        //   vendor_id: this.vendor?._id,
        //   user_id: this.client._id, //0,
        //   cart: [],
        // };
        const payload = {
          phone_number: _phone,
          amount: total_amount,
          transactionRef: this.orderID || "123",
        };
        const { data } = await mpesaPayment(payload);
        const { success, message } = data;

        if (success) {
          const { CustomerMessage } = data.data;
          await this.savePayment({
            clientId: this.client._id,
            order: this.orderID,
            employeeId: this.role._id,
            paymentMethod: "m-pesa",
            currency: "KES",
            amount: this.grandTotal,
            vendorCharge: 0,
          });
          this.isLoading = false;
          this.$router.push(`/order/${this.orderID}`);
          this.resetForms();
          this.$swal.fire("Success", CustomerMessage, "success");
          this.mpesaDialog = false;
        } else {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Mpesa payment Error",
            text: message,
          });
        }
      } catch (error: any) {
        this.isLoading = false;
        console.error(error.response);
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.message || "Payment Gateway Connection Error",
        });
      }
    },
    async doCardPayment() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }

      if (!this.vendor) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide Vendor Details",
        });
        return;
      }

      if (!this.isStripeSetup) {
        this.$swal.fire({
          icon: "error",
          title: "Stripe Gateway Not Initialized",
          text: "Stripe Account Details Not Set",
        });
        return;
      }

      let shipping: any = null;

      if (this.products.length > 0) {
        const { country, city, addressline1, phone } = this
          .addressChoice as any;
        if (!this.selectedShippingMethod) {
          this.$swal.fire({
            icon: "error",
            title: "Please select a shipping Method",
            text: "Please select a shipping Method",
          });
          return;
        }

        if (country == "" || city == "" || addressline1 == "" || phone == "") {
          this.$swal.fire({
            icon: "error",
            title: "Shipping Address",
            text: "Please provide your shipping Details",
          });
          return;
        }
        shipping = {
          contact: phone,
          delivery_note: "notes",
          shipping_method: (this.selectedShippingMethod as any).shipping_method
            ._id,
          country,
          city,
          address: addressline1,
          shipping_cost: this.shippingCost,
        };
      }

      if (this.orderID == "") {
        const order: any = await this.saveOrder({
          clientId: this.client._id,
          notes: "Order Notes",
          cost: this.grandTotal,
          shipping,
          paymentMethod: "card",
          products: this.products.map((product) => {
            return {
              product: product.product._id,
              sku: product.sku,
              quantity: product.quantity,
              unitPrice: product.unitPrice,
              total: product.sub_total,
            };
          }),
          services: this.services.map((service) => {
            return {
              name: service.name,
              service: service.id,
              staff: service.staff,
              appointmentDate: service.appointmentDate,
              appointmentTime: service.appointmentTime,
              quantity: service.quantity,
              unitPrice: service.unitPrice,
              total: service.sub_total,
            };
          }),
        });

        if (!order) {
          this.$swal.fire({
            icon: "error",
            title: "Payment Not Initialized",
            text: "Please Create an Order",
          });
          return;
        } else {
          this.orderID = order._id;
        }
      }
      // const stripe = await loadStripe(stripePubKey);
      // try {
      //   this.isLoading = true;
      //   this.loadingMessage = "Processing Card Payment...";
      //   const {
      //     card_number,
      //     exp_date,
      //     cvc,
      //     client_name,
      //     client_email,
      //     client_phone,
      //     subscription,
      //   } = this.card;
      //   const exp_month = exp_date.split("/")[0];
      //   const exp_year = exp_date.split("/")[1];
      //   this.loadingMessage = "Processing Card Payment...";
      //   const { data } = await cardPayment({
      //     card_number,
      //     exp_month,
      //     exp_year,
      //     cvc,
      //     client_name,
      //     client_email,
      //     client_phone,
      //     order_id: this.orderID,
      //     amount: this.grandTotal,
      //     // currency: "KES",
      //     vendor_id: this.vendor._id,
      //     subscription,
      //   });
      //   const {
      //     payment_intent_client_secret,
      //     requires_action,
      //     success = false,
      //   } = data;

      //   if (success) {
      //     this.isLoading = false;
      //     this.savePayment({
      //       clientId: this.client._id,
      //       order: this.orderID,
      //       employeeId: this.role._id,
      //       paymentMethod: "card",
      //       currency: "KES",
      //       amount: this.grandTotal,
      //       vendorCharge: 0,
      //     });
      //     this.resetForms();
      //     this.$swal.fire("Success", "Payment Successful");
      //     this.cardDialog = false;
      //   }

      //   if (requires_action) {
      //     this.loadingMessage = "Card Transaction Authorization...";
      //     const result = await stripe?.confirmCardPayment(
      //       payment_intent_client_secret
      //     );
      //     if (result?.error) {
      //       this.isLoading = false;
      //       this.$swal.fire({
      //         icon: "error",
      //         title: "Payment Not Initialized",
      //         text: result?.error.message || "Authorization Error",
      //       });
      //     }
      //     if (result?.paymentIntent) {
      //       this.isLoading = false;
      //       // await this.savePayment({
      //       //   clientId: this.client._id,
      //       //   order: this.orderID,
      //       //   employeeId: this.role._id,
      //       //   paymentMethod: "card",
      //       //   currency: "KES",
      //       //   amount: this.grandTotal,
      //       // });
      //       this.$router.push(`/order/${this.orderID}`);
      //       this.orderID = "";
      //       this.$swal.fire("Success", "Payment Successful");
      //       this.cardDialog = false;
      //     }
      //   }
      // } catch ({ response }: any) {
      //   this.isLoading = false;
      //   const error = response as any;
      //   const _error = error?.data;
      //   if (
      //     _error.code == "parameter_missing" ||
      //     _error.code == "invalid_number" ||
      //     _error.code == "invalid_expiry_month" ||
      //     _error.code == "invalid_expiry_year" ||
      //     _error.code == "card_declined" ||
      //     _error.code == "amount_too_small"
      //   ) {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: "Payment Not Initialized",
      //       text: _error.message || "Please provide valid card details",
      //     });
      //   } else {
      //     const { message } = error.data;
      //     this.$swal.fire({
      //       icon: "error",
      //       title: "Payment Not Initialized",
      //       text: message || "An Error Occurred! please try again.",
      //     });
      //   }
      // }
    },
    async applyVoucher() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }
      if (this.gift_code != "") {
        // let payload = {
        //   gift_code: this.gift_code,
        //   checkout_amount: this.cartTotal,
        //   currency: "KES",
        //   country: "Kenya",
        //   vendor_id: this.vendor._id,
        //   client_id: this.client._id,
        //   cart: [],
        // };
        const payload = {
          code: this.gift_code,
          cart_total: this.cartTotal,
          client_id: this.client._id,
        };

        try {
          const { data } = await validateVoucherCode(payload);
          console.log(data);
          const { discount, message, success } = data;

          if (success) {
            this.$store.dispatch(
              "setToast",
              {
                title: "Voucher Applied",
                type: "success",
                text: message,
              },
              { root: true }
            );

            if (discount > 0) {
              this.discount = discount;
            }

            this.voucherDialog = false;
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Request failed! The promocode can not be applied",
              text: message,
            });
          }
        } catch ({ response }: any) {
          const error = response as any;
          const { message } = error;
          this.$swal.fire({
            icon: "error",
            title: "Request failed! The promocode can not be applied",
            text: message,
          });
        }
      }
    },
    async doCashPayment() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }
      let shipping: any = null;

      if (this.products.length > 0) {
        const { country, city, addressline1, phone } = this
          .addressChoice as any;
        if (!this.selectedShippingMethod) {
          this.$swal.fire({
            icon: "error",
            title: "Please select a shipping Method",
            text: "Please select a shipping Method",
          });
          return;
        }

        if (country == "" || city == "" || addressline1 == "" || phone == "") {
          this.$swal.fire({
            icon: "error",
            title: "Shipping Address",
            text: "Please provide your shipping Details",
          });
          return;
        }
        shipping = {
          contact: phone,
          delivery_note: "notes",
          shipping_method: (this.selectedShippingMethod as any).shipping_method
            ._id,
          country,
          city,
          address: addressline1,
          shipping_cost: this.shippingCost,
        };
      }

      try {
        let payload = {
          checkout_amount: this.cash.total_amount,
          cash_amount: this.cash.total_amount,
          currency: "KES",
          country: "Kenya",
          vendor_id: this.vendor._id,
          client_id: this.client._id,
          order_id: this.orderID || "123",
          cart: [],
        };
        const { data } = await cashPayment(payload);
        const { success, message, remainingAmount } = data;

        if (success) {
          const order: any = await this.saveOrder({
            clientId: this.client._id,
            notes: "Cash Payment of Order",
            cost: this.cash.total_amount,
            shipping,
            paymentMethod: "cash",
            products: this.products.map((product) => {
              return {
                product: product.product._id,
                sku: product.sku,
                quantity: product.quantity,
                unitPrice: product.unitPrice,
                total: product.sub_total,
              };
            }),
            services: this.services.map((service) => {
              return {
                name: service.name,
                service: service.id,
                staff: service.staff,
                appointmentDate: service.appointmentDate,
                appointmentTime: service.appointmentTime,
                quantity: service.quantity,
                unitPrice: service.unitPrice,
                total: service.sub_total,
              };
            }),
          });

          await this.savePayment({
            clientId: this.client._id,
            order: order._id || this.orderID,
            paymentMethod: "cash",
            currency: "KES",
            amount: this.cash.total_amount - (remainingAmount || 0),
            vendorCharge: 0,
          });

          this.cashDialog = false;
          this.$router.push(`/order/${order._id}`);
          this.resetForms();
          this.$swal.fire({
            icon: "success",
            title: "Order Created",
            text: "Order Created and Paid successful ",
          });
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Payment failed!",
            text: message,
          });
        }
      } catch ({ response }: any) {
        const error = response as any;
        const { message } = error.data;
        this.$swal.fire({
          icon: "error",
          title: "Cash Payment Error",
          text: message || "An Error Occurred!",
        });
      }
    },
    saveOrder(data: any) {
      return new Promise((resolve, reject) => {
        if (this.role && this.orderId) {
          const businessId = (this.role.business as Business)._id;
          this.updateOrder({
            businessId,
            orderId: this.orderId,
            ...data,
          })
            .then((order) => {
              resolve(order);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error("Order Not Initialized"));
        }
      });
    },

    fetchOrder(orderId: any) {
      return new Promise((resolve, reject) => {
        if (this.role) {
          const businessId = (this.role.business as Business)._id;
          this.fetchOrderList(`?orderId=${orderId}&businessId=${businessId}`)
            .then((order) => {
              resolve(order);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error("Order Not Initialized"));
        }
      });
    },

    loadOrder(order_id: string) {
      if (this.role && this.vendor) {
        const params = `?orderId=${order_id}&businessId=${this.vendor._id}`;
        this.fetchOrderList(params);
      }
    },

    saveMembershipSession(data: {
      businessId: string;
      clientId: string;
      membershipId: string;
      quantity: number;
    }) {
      return this.createMembershipSession(data);
    },

    savePayment(data: any) {
      return this.createPayment(data);
    },
    resetForms() {
      this.mpesa.phone = "";
      this.cash = {
        cash_amount: 0,
        cash_balance: 0,
        total_amount: 0,
      };
      this.card = {
        total_amount: 0,
        card_number: undefined,
        cvc: undefined,
        exp_date: "",
        client_name: "",
        client_email: "",
        client_phone: "",
        subscription: false,
      };
      this.discount = 0;
      this.gift_code = "";
      this.selectedShippingMethod = undefined;
      this.shipping = {
        country: "",
        city: "",
        address: "",
        phone: "",
        notes: "",
      };
      this.$store.dispatch("cart/deleteCart");
    },

    resetQrPayment() {
      this.qr.step = 1;
      this.qrDialog = false;
      this.qr.creatingOrder = false;
      this.qr.fetchingOrder = false;
      this.qrcode = "";
      this.orderID = "";
      this.qr.timer = 300;

      clearInterval(this.qr.interval as any);
    },

    regenerateQRCode() {
      this.qr.step = 1;
      this.qr.creatingOrder = false;
      this.qrcode = "";
      this.qr.timer = 300;

      this.generateQRCode();
    },

    qrTimer() {
      if (this.qr.timer > 0) {
        setTimeout(() => {
          this.qr.timer -= 1;
          this.qrTimer();
        }, 1000);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_CHECKOUT")) {
      this.$store.registerModule("ORDER_CHECKOUT", OrderStoreModule);
    }

    if (!this.$store.hasModule("ORDER_PAYMENTS")) {
      this.$store.registerModule("ORDER_PAYMENTS", PaymentStoreModule);
    }

    if (!this.$store.hasModule("PAYMENT_GATEWAY")) {
      this.$store.registerModule("PAYMENT_GATEWAY", PaymentGatewayModule);
    }

    if (!this.$store.hasModule("EMPLOYEES_CHECKOUT")) {
      this.$store.registerModule("EMPLOYEES_CHECKOUT", employeeStoreModule);
    }

    if (!this.$store.hasModule("GATEWAY")) {
      this.$store.registerModule("GATEWAY", GatewayModule);
    }
    if (!this.$store.hasModule("_Membership")) {
      this.$store.registerModule("_Membership", MembershipModule);
    }

    if (!this.$store.hasModule("_Client")) {
      this.$store.registerModule("_Client", ClientModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ORDER_CHECKOUT");
    this.$store.unregisterModule("ORDER_PAYMENTS");
    this.$store.unregisterModule("PAYMENT_GATEWAY");
    this.$store.unregisterModule("EMPLOYEES_CHECKOUT");
    this.$store.unregisterModule("_Membership");
    this.$store.unregisterModule("_Client");
  },
});
